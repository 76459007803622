import "./BuyMeCoffee.css"
import { createRoot } from "react-dom/client";
import {fetchGifs} from "../utils";

const BuyMeCoffeeGif = async () => {
    const offset = Math.floor(Math.random() * 37);
    const result = await fetchGifs("coffee", [], offset, 1);
    const gif = result["gifs"][0];
    const gifElement = <video className="gif" loop autoPlay muted playsInline disablePictureInPicture controlsList="nodownload nofullscreen noremoteplayback">
                      <source src={`data:video/mp4;base64,${gif.data}`} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>;
    const parentElement = document.getElementById("bmc-wbtn").nextSibling
    parentElement.classList.add("buy-me-coffee");
    createRoot(parentElement).render(gifElement);
    setTimeout(() => parentElement.remove(), 10000);
  }
  BuyMeCoffeeGif();